import React from 'react'
import styles from './About.module.css';
import { Link } from 'react-router-dom';
import Slider from '../components/Slider';
import ScrollContents from '../components/ScrollContents';
const About = () => {
  
  return (
    <div className={styles.container}>
      <section className={styles.fv_area}>
        <h1>ABOUT</h1>
      </section>
      
      <section className={styles.about_area}>
        <div className={styles.flex}>
          <div>
            <h2>
              About Us<br />
              <span>私たちについて</span>
            </h2>
          </div>
          <div>
            <div>未来をデザインするWebソリューションのパートナー</div>
            <p>
              私たちは最先端のテクノロジーを駆使して、ビジネスに革新的な変化をもたらすWebソリューションを提供しています。<br />
              設立以来、お客様の成功を最優先に考え、最高品質のサービスを提供することに専念しております。
            </p>
          </div>
        </div>
      </section>

      {/* <section className={styles.concept_area}>
        <h2>
          Vision<br />
          <span>企業理念</span>
        </h2>
      </section> */}


      <section className={styles.feature_area}>
        <h2>
          Feature<br />
          <span>サービス・強み</span>
        </h2>
        <Slider />
      </section>

      <section className={styles.cta_area}>
        <p>
          ご興味のある方は、お気軽にお問合せください。
        </p>
        <Link to="/contact">
          <div className={styles.flex}>
            <div>CONTACT</div>
            <div>無料相談・お見積り</div>
          </div>
        </Link>
      </section>

      <section className={styles.works_area}>
        <h2>
          Works<br />
          <span>制作事例</span>
        </h2>
        <ScrollContents />
      </section>

      <section className={styles.process_area}>
        <h2>
          Process<br />
          <span>ご相談の流れ</span>
        </h2>
        <div className={styles.cards}>
          <div className={styles.flex}>
            <div>
              <div>
                PHASE<br />
                01
              </div>
            </div>
            <div>
              <div>ヒアリング・御見積</div>
              <p>
                ご依頼内容をヒアリングし、まずはスケジュール・費用概算を共有致します。
              </p>
            </div>
          </div>
          <div className={styles.flex}>
            <div>
              <div>
                PHASE<br />
                02
              </div>
            </div>
            <div>
              <div>仕様検討・要件定義</div>
              <p>
                事前ヒアリングの情報を基に、戦略的な企画書と詳細な設計図を作成します。この段階で、ウェブサイトの構造、デザイン、機能などを決定し、お客様と共有します。
              </p>
            </div>
          </div>
          <div className={styles.flex}>
            <div>
              <div>
                PHASE<br />
                03
              </div>
            </div>
            <div>
              <div>WEBデザイン・システム開発</div>
              <p>
                企画と設計の確定後、開発作業に入ります。最先端のテクノロジーを使用して、企画書通りのウェブサイトを効率的に構築します。
              </p>
            </div>
          </div>
          <div className={styles.flex}>
            <div>
              <div>
                PHASE<br />
                04
              </div>
            </div>
            <div>
              <div>保守・運用</div>
              <p>
                ウェブサイト完成後、厳密なテストフェーズに入ります。ユーザビリティ、セキュリティ、レスポンシブデザインなど、多角的にサイトを評価し、必要な修正を行います。
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className={styles.client_area}>
        <h2>
          Our Clients<br />
          <span>取引先企業</span>
        </h2>
        <ul>
          <li>
            <img src='/images/logo.png' alt='img' />
          </li>
          <li>
            <img src='/images/logo.png' alt='img' />
          </li>
          <li>
            <img src='/images/logo.png' alt='img' />
          </li>
          <li>
            <img src='/images/logo.png' alt='img' />
          </li>
          <li>
            <img src='/images/logo.png' alt='img' />
          </li>
          <li>
            <img src='/images/logo.png' alt='img' />
          </li>
          <li>
            <img src='/images/logo.png' alt='img' />
          </li>
          <li>
            <img src='/images/logo.png' alt='img' />
          </li>
          <li>
            <img src='/images/logo.png' alt='img' />
          </li>
          <li>
            <img src='/images/logo.png' alt='img' />
          </li>
        </ul>
      </section> */}
    </div>
  )
}

export default About