import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => {
    setIsMenuOpen(false);
  }

  return (
    <header>
        <h1>
            <Link to="/"><img src='/images/logo.png' className={styles.logo} alt='img'/> </Link>
        </h1>
        <div className={styles.menuIcon} onClick={() => setIsMenuOpen(true)}>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <nav className={isMenuOpen ? styles.open : ''}>
            <div className={styles.closeButton} onClick={closeMenu}>×</div>
            <ul>
                <li><Link to="/about" onClick={closeMenu}>ABOUT</Link></li>
                <li><Link to="/company" onClick={closeMenu}>COMPANY</Link></li>
                <li><Link to="/news" onClick={closeMenu}>NEWS</Link></li>
                <li><Link to="/contact" onClick={closeMenu}>CONTACT</Link></li>
            </ul>
        </nav>
    </header>
  )
}

export default Header;


