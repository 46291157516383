import React from 'react'
import Particles from './Particles'
// import { OrbitControls } from '@react-three/drei/core/OrbitControls';
import { Canvas } from '@react-three/fiber';
import styles from './Bg.module.css';
import Particles2 from './Particles2';

const Bg = () => {
  return (
    <section className={styles.bg_area}>
        <Canvas dpr={2}>
            {/* <OrbitControls/> */}
            <Particles />
            <Particles2 />
            <ambientLight />

        </Canvas>
    </section>
    
  )
}

export default Bg