import React from 'react';
import styles from './Complete.module.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const Complete = () => {
  return (
    <div className={styles.container}>
      <section className={styles.fv_area}>
        <h1>COMPLETE</h1>
      </section>
      <section className={styles.form_area}>
        <div className={styles.flex}>
          <div>
            <h3>お問い合わせありがとうございます。</h3>
            <p>
              送信いただいた内容を確認後、1営業日以内にご返信いたします。
            </p>
            <div className={styles.button_wrapper}>
              <Button 
                  variant="outlined"
                  type="submit"
                >
                  <Link to="/"> TOP</Link>
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Complete;

