import React from 'react';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
        <div className={styles.flex}>
            <div>
                <Link to="/"><img src='/images/logo.png' alt='img'/></Link>
            </div>
            <div>
                <ul>
                    <li>
                        <Link to="/about">ABOUT</Link>
                    </li>
                    <li>
                        <Link to="/company">COMPANY</Link>
                    </li>
                    <li>
                        <Link to="/news">NEWS</Link>
                    </li>
                    <li>
                        <Link to="/contact">CONTACT</Link>
                    </li>
                </ul>
            </div>
        </div>
        <p>&copy; {new Date().getFullYear()} Cellnology. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
