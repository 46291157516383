import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import styles from './News.module.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const NewsCard = ({ id, category, date, title, bgImage }) => (
  <div className={styles.card} style={{ background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${bgImage})` }}>
    <div className={styles.date}><span>{category}</span>{date}</div>
    <div className={styles.title}>{title}</div>
    <div className={styles.button_wrapper}>
        <Button variant="outlined" component={Link} to={`/news/${id}`}>View More</Button>
    </div>
  </div>
);

const News = () => {
  const [value, setValue] = useState(1);

  const newsData = [
    {
      id: '2',
      category: "Company",
      date: "2023.09.04",
      title: "ISMS認証（ISO27001）取得のお知らせ",
      bgImage: "/images/news-bg-b1.png"
    },
    {
      id: '1',
      category: "Press Release",
      date: "2023.05.28",
      title: "株式会社セルノロジー設立のお知らせ",
      bgImage: "/images/news-bg-d1.png"
    },
    // 他のニュースデータ
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderNews = (category = null) => {
    return newsData
      .filter((item) => category === null || item.category === category)
      .map((item, index) => (
        <NewsCard
          key={index}
          id={item.id}
          category={item.category}
          date={item.date}
          title={item.title}
          bgImage={item.bgImage}
        />
      ));
  };

  return (
    <div className={styles.container}>
      <section className={styles.fv_area}>
        <h1>NEWS</h1>
      </section>
      <section className={styles.contents_area}>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" variant="scrollable" scrollButtons allowScrollButtonsMobile className={styles.tab}>
          <Tab label="Category" className={`${styles.tab_button} ${styles.category}`} disabled />
          <Tab label="All" className={styles.tab_button} />
          <Tab label="Company" className={styles.tab_button}/>
          <Tab label="Media" className={styles.tab_button}/>
          <Tab label="Press Releases" className={styles.tab_button}/>
        </Tabs>

        <div className={styles.content}>
          {value === 1 && renderNews()}
          {value === 2 && renderNews("Company")}
          {value === 3 && renderNews("Media")}
          {value === 4 && renderNews("Press Release")}
        </div>
      </section>
    </div>
  );
};

export default News;

