import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Top from './pages/Top';
import About from './pages/About';
import Company from './pages/Company';
import Contact from './pages/Contact';
import News from './pages/News';
import Header from './components/Header';
import Confirm from './pages/Confirm';
import Complete from './pages/Complete';
import Footer from './components/Footer';
import Bg from './components/Bg';
// import Breadcrumbs from './components/Breadcrumbs';
import { useEffect } from 'react';
import NewsDetail2 from './pages/NewsDetail2';
import NewsDetail1 from './pages/NewsDetail1';


const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
}

const ScrollToTop = () => {
  useScrollToTop();
  return null;
}
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      {/* <Breadcrumbs /> */}
      <main className="App"> 
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/about" element={<About />} />
          <Route path="/company" element={<Company />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/complete" element={<Complete />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/1" element={<NewsDetail1 />} />
          <Route path="/news/2" element={<NewsDetail2 />} />
        </Routes>
      </main>
      <Footer />
      <Bg />
    </Router>
  );
}

export default App;

