import * as THREE from 'three';
import React, { Suspense, useEffect, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Reflector, Text, useTexture } from '@react-three/drei';
import styles from './FirstView.module.css';
import { TextureLoader } from 'three';

const FirstView = () => {
    const [fontSize, setFontSize] = useState(window.innerWidth <= 768 ? 0.6 : 1.4);
    const [displayText, setDisplayText] = useState(window.innerWidth <= 768 ? "CELL\nNOLOGY" : "CELLNOLOGY");
    
    const updateFontSize = () => {
        setFontSize(window.innerWidth <= 768 ? 0.6 : 1.4);
    };

    const updateDisplayText = () => {
        setDisplayText(window.innerWidth <= 768 ? "CELL\nNOLOGY" : "CELLNOLOGY");
    };

    useEffect(() => {
        window.addEventListener('resize', updateFontSize);
        window.addEventListener('resize', updateDisplayText);
        return () => {
            window.removeEventListener('resize', updateFontSize);
            window.removeEventListener('resize', updateDisplayText);
        };
    }, []);

    function VideoText(props) {
        const [video, setVideo] = useState(null);
        const [imageTexture, setImageTexture] = useState(null); // テクスチャを格納するためのstate
    
        useEffect(() => {
            if (window.innerWidth > 768) {
                const vid = document.createElement('video');
                Object.assign(vid, { src: '/video.mp4', crossOrigin: 'Anonymous', loop: true, muted: true });
                setVideo(vid);
                vid.play();
            } else {
                const loader = new TextureLoader();
                loader.load('/sample.png', texture => {
                    setImageTexture(texture); // テクスチャをstateにセット
                });
            }
        }, []);
    
        return (
            <Text font="/Inter-Bold.woff" fontSize={fontSize} letterSpacing={-0.06} anchorX="center" textAlign="center" {...props}>
                {displayText}
                {video && (
                    <meshBasicMaterial toneMapped={false}>
                        <videoTexture attach="map" args={[video]} />
                    </meshBasicMaterial>
                )}
                {imageTexture && (
                    <meshBasicMaterial map={imageTexture} toneMapped={false} />
                )}
            </Text>
        );
    }

    function Ground() {
        const [floor, normal] = useTexture(['/SurfaceImperfections003_1K_var1.jpg', '/SurfaceImperfections003_1K_Normal.jpg']);
        return (
            <Reflector blur={[400, 100]} resolution={512} args={[10, 10]} mirror={0.8} mixBlur={6} mixStrength={7} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
                {(Material, props) => <Material color="#a0a0a0" metalness={0.4} roughnessMap={floor} normalMap={normal} normalScale={[2, 2]} transparent={true} opacity={0.6} {...props} />}
            </Reflector>
        );
    }

    function Intro() {
        const [vec] = useState(() => new THREE.Vector3());
        return useFrame((state) => {
            state.camera.position.lerp(vec.set(state.mouse.x * 5, 3 + state.mouse.y * 2, 14), 0.05);
            state.camera.lookAt(0, 0, 0);
        });
    }

    return (
        <div className={styles.fv_contents}>
            <Canvas concurrent="true" gl={{ alpha: true }} dpr={[1, 1.5]} camera={{ position: [0, 3, 100], fov: 15 }}>
                <fog attach="fog" args={['black', 15, 20]} />
                <Suspense fallback={null}>
                    <group position={[0, -1, 0]}>
                        <VideoText position={[0, 1.3, -2]} />
                        <Ground />
                    </group>
                    <ambientLight intensity={0.5} />
                    <spotLight position={[0, 10, 0]} intensity={0.3} />
                    <directionalLight position={[-50, 0, -40]} intensity={0.7} />
                    <Intro />
                </Suspense>
            </Canvas>
        </div>
    );
};

export default FirstView;
