import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { useIntersect, Image, ScrollControls, Scroll } from '@react-three/drei';
import styles from './ThreeScroll.module.css';
import { Button } from '@mui/material';

const ThreeScroll = () => {
  function Item({ url, scale, ...props }) {
    const visible = useRef(false);
    const [hovered, hover] = useState(false);
    const ref = useIntersect((isVisible) => (visible.current = isVisible));
    const { height } = useThree((state) => state.viewport);

    useFrame((state, delta) => {
      ref.current.position.y = THREE.MathUtils.damp(ref.current.position.y, visible.current ? 0 : -height / 2 + 1, 4, delta);
      ref.current.material.zoom = THREE.MathUtils.damp(ref.current.material.zoom, visible.current ? 1 : 1.5, 4, delta);
      ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, hovered ? 1 : 0, 4, delta);
    });

    return (
      <group {...props}>
        <Image ref={ref} onPointerOver={() => hover(true)} onPointerOut={() => hover(false)} scale={scale} url={url} />
      </group>
    );
  }

  const Items = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 430);
    const { width: w } = useThree((state) => state.viewport);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setIsSmallScreen(window.innerWidth <= 430);
      };

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const getPositionForItem = (defaultPosition, smallScreenPosition, tinyScreenPosition) => {
      if (isSmallScreen) {
        return tinyScreenPosition;
      }
      return windowWidth <= 768 ? smallScreenPosition : defaultPosition;
    };

    const getScaleForItem = (defaultScale, smallScreenScale, tinyScreenScale) => {
      if (isSmallScreen) {
        return tinyScreenScale;
      }
      return windowWidth <= 768 ? smallScreenScale : defaultScale;
    };

    return (
      <Scroll>
        <Item 
          url="/images/three-scroll-img.png" 
          scale={getScaleForItem([w / 4, w / 2, 1], [w / 3, w / 1.2, 1], [w / 3, w / 1, 1])}
          position={getPositionForItem([w / 2 - (w / 3) / 2, -3, 0], [w / 2 - (w / 3) / 2, -3, 0], [w / 2 - (w / 3) / 2, -4, 0])}
        />
        <Item 
          url="/images/three-scroll-img-2.png"
          scale={getScaleForItem([w / 4, w / 5, 2], [w / 3, w / 5, 2], [w / 2, w / 3, 2])} 
          position={getPositionForItem([w / 2 - (w / 3) / 1.0, -6.5, 0], [w / 2 - (w / 3) / 1.0, -6.5, 0], [w / 2 - (w / 3) / 1.0, -6, 0])}
         />
  
        <Item 
          url="/images/three-scroll-img-3.png"
          scale={getScaleForItem([w / 2, w / 2, 1], [w / 2.2, w / 1.5, 1], [w / 2.2, w / 0.9, 1])} 
          position={getPositionForItem([-w / 2 + (w / 3) / 2, -18, 0], [-w / 10 + (w / 3) / 1, -18, 0], [-w / 10 + (w / 3) / 0.9, -18.5, 0])} 
        />
  
        <Item 
          url="/images/three-scroll-img-4.png" 
          scale={getScaleForItem([w / 4, w / 2, 1], [w / 4.2, w / 1.2, 1], [w / 4.2, w / 1, 1])} 
          position={getPositionForItem([w / 2 - (w / 3) / 2.4, -33, 0], [w / 2 - (w / 3) / 2.4, -30, 0], [w / 2 - (w / 3) / 2.4, -30, 0])}
         />
                
        <Item 
          url="/images/three-scroll-img-5.png" 
          scale={getScaleForItem([w / 5.8, w / 2.2, 2], [w / 5.5, w / 1.2, 2], [w / 5.5, w / 1, 2])}
          position={getPositionForItem([w / 2 - (w / 3) / 0.9, -35, 0], [w / 2 - (w / 3) / 0.9, -32, 0], [w / 2 - (w / 3) / 0.9, -32, 0])}
         />
        {/* 他の Item コンポーネントにも同様の修正を加えてください */}
      </Scroll>
    );
  };

  return (
    <div className={styles.threescroll_wrapper}>
      <Canvas orthographic camera={{ zoom: 80 }} gl={{ alpha: true, antialias: false, stencil: false, depth: false }} dpr={[1, 1.5]}>
        <ScrollControls damping={0.5} pages={4.5}>
          <Items />
          <Scroll html style={{ width: '100%' }}>
            <section className={styles.head}>
              <div className={styles.title}>
                Feature
                <span>サービス特長・強み</span>
              </div>
              <p>
              マーケティングの360度をカバーするため、親会社CELLMULLERではマーケティングに必要なすべての事業を網羅しています。<br />
              CELLNOLOGYではマーケティングに関わるTECH領域を担当し、<br />お客様の問題をマーケティングとテクノロジー両方の視点で解決します。
              </p>
            </section>
            <section className={`${styles.section_wrapper} ${styles.section_1}`}>
              <div className={styles.title}>
                <span>Web制作</span>
                WEBDESIGN <br />SERVICE
              </div>
              <p>
                広告用クリエイティブバナーを始め、ランディングページの制作、<br />コーポレートサイトの制作まで弊社で全て承ります。<br />
                弊社の制作は納品までで完結せず、その後のサイト分析・LPOまでも担当致します。
              </p>
              <Button variant="outlined" onClick={() => window.location.href = '/about'}>Detail</Button>
            </section>

            <section className={`${styles.section_wrapper} ${styles.section_2}`}>
              <div className={styles.title}>
                <span>データ分析</span>
                DATA ANALYTICS <br />SERVICE
              </div>
              <p>
                マーケティングカンパニー由来のノウハウを生かし、<br />
                担当者の主観に頼らないデータに基づいた課題・打ち手を抽出いたします。
              </p>
              <Button variant="outlined" onClick={() => window.location.href = '/about'}>Detail</Button>
            </section>

            <section className={`${styles.section_wrapper} ${styles.section_3}`}>
              <div className={styles.title}>
                <span>開発</span>
                SYSTEM /<br /> APP DEVELOP<br />SERVICE
              </div>
              <p>
                弊社の制作は、一概にWeb広告に携わるものだけではありません。<br />
                HPの制作、LPの制作、そしてシステム開発も含む全領域を担当します。<br />
                お客様に合わせた業務効率化ツールの開発、<br />
                データ分析を用いたLPOまで幅広く実現いたします。
              </p>
              <Button variant="outlined" onClick={() => window.location.href = '/about'}>Detail</Button>
            </section>
          </Scroll>
        </ScrollControls>
      </Canvas>
    </div>
  );
};

export default ThreeScroll;
