import React from 'react'
import styles from './NewsDetail.module.css';

const NewsDetail1 = () => {
  return (
    <div className={styles.container}>
        <section className={styles.contents_area}>
            <div className={styles.title}>
                <div>Press Release<span>2023.05.28</span></div>
                株式会社セルノロジー設立のお知らせ
            </div>
            <img src='/images/logo.png' alt='img' />
            <div className={styles.text}>
                <p>
                    この度、株式会社セルノロジー(東京都港区、代表：由利尚真)が設立されたことをお知らせいたします。
                </p>
            </div>
        </section>
    </div>
  )
}

export default NewsDetail1
