import React from 'react'
import styles from './Company.module.css';
import { Link } from 'react-router-dom';

const Company = () => {
  return (
    <div className={styles.container}>
      <section className={styles.fv_area}>
        <h1>COMPANY</h1>
      </section>
      <section className={styles.prof_area}>
        <h2>
          Company<br />
          <span>会社概要</span>
        </h2>
        <div className={styles.info}>
          <div className={styles.flex}>
            <div>会社名</div>
            <div>株式会社セルノロジー</div>
          </div>
          <div className={styles.flex}>
            <div>所在地</div>
            <div>
              〒106-0032<br />
              東京都港区六本木7丁目15-17 ユニ六本木ビル 10F<br />
              <Link to="#">Google Map</Link>
            </div>
          </div>
          <div className={styles.flex}>
            <div>事業内容</div>
            <div>
              ・Web制作事業<br />
              ・Webシステム開発事業<br />
            </div>
          </div>
          <div className={styles.flex}>
            <div>設立</div>
            <div>2023年6月</div>
          </div>
          <div className={styles.flex}>
            <div>代表者</div>
            <div>由利尚真</div>
          </div>
        </div>
      </section>
      <section className={styles.prof_area}>
        <h2>
          GroupCompany<br />
          <span>関連会社</span>
        </h2>
        <div className={styles.info}>
          <div className={styles.flex}>
            <div>会社名</div>
            <div>株式会社セルミュラー</div>
          </div>
        </div>
      </section>
    </div>
    
  )
}

export default Company