import React, { useState, useEffect } from 'react';
import Carousel from 'react-spring-3d-carousel';
import { v4 as uuidv4 } from 'uuid';
import styles from './Slider.module.css';

const Slider = () => {
  const [goToSlide, setGoToSlide] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imageSuffix, setImageSuffix] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 968) {
      setImageSuffix('sp-');
    } else {
      setImageSuffix('');
    }
  }, [windowWidth]);

  const slides = [
    {
      key: uuidv4(),
      content: (
        <div className={styles.slide_content}>
          <img src={`/images/slider-${imageSuffix}img.png`} alt="img" className={styles.slider_img}/>
          <div className={styles.slide_text}>
            <div className={styles.title}>
              WEBDESIGN SERVICE
              <span>Web制作</span>
            </div>
            <p>
              広告用クリエイティブバナーを始め、ランディングページの制作、コーポレートサイトの制作まで弊社で全て承ります。弊社の制作は納品までで完結せず、その後のサイト分析・LPOまでも担当致します。
            </p>
            <div className={styles.marks}>
              <div className={styles.mark}>Web Design</div>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className={styles.slide_content}>
          <img src={`/images/slider-${imageSuffix}img-2.png`} alt="img" className={styles.slider_img}/>
          <div className={styles.slide_text}>
            <div className={styles.title}>
            DATA ANALYTICS SERVICE
              <span>データ分析</span>
            </div>
            <p>
              マーケティングカンパニー由来のノウハウを生かし、<br />
              担当者の主観に頼らないデータに基づいた課題・打ち手を抽出いたします。
            </p>
            <div className={styles.marks}>
              <div className={styles.mark}>CRM</div>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className={styles.slide_content}>
          <img src={`/images/slider-${imageSuffix}img-3.png`} alt="img" className={styles.slider_img}/>
          <div className={styles.slide_text}>
            <div className={styles.title}>
            SYSTEM / APP DEVELOP SERVICE
              <span>開発</span>
            </div>
            <p>
              弊社の制作は、一概にWeb広告に携わるものだけではありません。<br />
              HPの制作、LPの制作、そしてシステム開発も含む全領域を担当します。<br />
              お客様に合わせた業務効率化ツールの開発、データ分析を用いたLPOまで幅広く実現いたします。
            </p>
            <div className={styles.marks}>
              <div className={styles.mark}>DEVELOP</div>
              <div className={styles.mark}>CRM</div>
            </div>
          </div>
        </div>
      ),
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => setGoToSlide(index) };
  });

  return (
    <div className={styles.carousel}>
      <Carousel
        slides={slides}
        goToSlide={goToSlide}
        showNavigation={true}
        animationConfig={{ tension: 120, friction: 14 }}
        offsetRadius={1}
      />
    </div>
  );
};

export default Slider;

