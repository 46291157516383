import React from 'react';
import axios from 'axios';
import styles from './Confirm.module.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Confirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data;

  if (!data) {
    return <div>No data received</div>;
  }
  const handleSubmit = async () => {
    try {
      // ここで send_mail.php に POST リクエストを送る
      const response = await axios.post('./send_mail.php', data);
      if (response.status === 200) {
        // 成功した場合、/complete に遷移
        navigate('/complete');
      }
    } catch (error) {
      console.error('There was an error sending the email', error);
    }
  };

  return (
    <div className={styles.container}>
      <section className={styles.fv_area}>
        <h1>CONFIRM</h1>
      </section>
      <section className={styles.form_area}>
        <div className={styles.flex}>
          <div>
            <h2>Contact Us</h2>
          </div>
          <div>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField 
                id="standard-basic" 
                label="name" 
                variant="standard" 
                value={data.name} 
                InputProps={{ readOnly: true }}
              />
              <TextField 
                id="standard-basic" 
                label="email" 
                variant="standard" 
                value={data.email} 
                InputProps={{ readOnly: true }}
              />
              <TextField 
                id="standard-basic" 
                label="email-conf" 
                variant="standard" 
                value={data.emailConf} 
                InputProps={{ readOnly: true }}
              />
              <TextField
                id="standard-multiline-flexible"
                label="content"
                multiline
                maxRows={4}
                variant="standard"
                value={data.content}
                InputProps={{ readOnly: true }}
              />
              <div className={styles.button_wrapper}>
                <Button variant="outlined" className={styles.back_button}>
                  <Link to="/contact">Back</Link>
                </Button>
                <Button 
                  variant="outlined" 
                  className={styles.submit_button}
                  onClick={handleSubmit} // ここで handleSubmit を呼び出す
                >
                  Submit
                </Button>
              </div>
              
            </Box>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Confirm;

