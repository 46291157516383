import React from 'react'
import styles from './Top.module.css';
import Button from '@mui/material/Button';
import FirstView from '../components/FirstView';
import ThreeScroll from '../components/ThreeScroll';
import { Link } from 'react-router-dom';
const Top = () => {
  return (
    <div>
      <section className={styles.fv_area}>
        <FirstView />
      </section>
      <div className={styles.container}>
        <section className={styles.about_area}>
          <h2>
            About Us<br />
            <span>私たちについて</span>
          </h2>
          <p>
          私たちは最先端のテクノロジーを駆使して、<br />ビジネスに革新的な変化をもたらすWebソリューションを提供しています。<br />
          設立以来、お客様の成功を最優先に考え、最高品質のサービスを提供することに専念しております。
          </p>
          <div className={styles.three_scroll}>
            <ThreeScroll />
          </div>
          <div className={styles.flex}>
            <div>
              マーケティングの360度をカバーするため、親会社CELLMULLERではマーケティングに必要なすべての事業を網羅しています。
              CELLNOLOGYではマーケティングに関わるTECH領域を担当し、お客様の問題をマーケティングとテクノロジー両方の視点で解決します。
            </div>
            <div>
            <Button variant="outlined" component={Link} to="/about">About</Button>
            </div>
          </div>

        </section>

        <section className={styles.company_area}>
          <h2>
            Company<br />
            <span>会社情報</span>
          </h2>
          <div className={styles.flex}>
            <div>
              <div>
                未来を形作るWebソリューションのエキスパート
              </div>
              <p>
                私たちは、先進的なテクノロジーを活用してビジネスに革新を起こす専門家です。設立当初から現在に至るまで、お客様の成功が我々の最大の目標。この信念の下、我々は絶えず高品質なウェブサービスを提供し続けています。
              </p>
              <Button variant="outlined" component={Link} to="/about">Company</Button>
            </div>
            <div>
              <iframe 
                allowFullScreen referrerPolicy="no-referrer"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.597130446796!2d139.72706287627096!3d35.662296231013144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188dd6b63ca0b9%3A0x193e046db563fc0b!2z44ix44K744Or44Of44Ol44Op44O8!5e0!3m2!1sja!2sjp!4v1693988659341!5m2!1sja!2sjp" 
                width="500" 
                height="450" 
                loading="lazy" 
                className={styles.google_map} 
                title='map'>
              </iframe>
            </div>
          </div>
        </section>

        <section className={styles.news_area}>
          <h2>
            News<br />
            <span>ニュース</span>
          </h2>
          <div className={styles.cards}>
            <div className={styles.card}>
              <div>
                <img src='/images/top-news-img-2.png' alt='img' />
              </div>
              <div>
                <div>2023.09.04</div>
                <p>
                  ISMS認証（ISO27001）取得のお知らせ
                </p>
                <div>
                  <Button variant="outlined" component={Link} to="/news/2">View More</Button>
                </div>
                
              </div>
            </div>
            <div className={styles.card}>
              <div>
                <img src='/images/top-news-img.png' alt='img' />
              </div>
              <div>
                <div>2023.05.28</div>
                <p>
                  株式会社セルノロジー設立のお知らせ
                </p>
                <div>
                  <Button variant="outlined" component={Link} to="/news/1">View More</Button>
                </div>
                
              </div>
            </div>
          </div>
          <div className={styles.button_wrapper}>
            <Button variant="outlined" component={Link} to="/news">News</Button>
          </div>
        </section>
      </div>
    </div>
    
  )
}

export default Top