import React from 'react'
import styles from './NewsDetail.module.css';

const NewsDetail2 = () => {
  return (
    <div className={styles.container}>
        <section className={styles.contents_area}>
            <div className={styles.title}>
                <div>Company<span>2023.09.04</span></div>
                ISMS認証（ISO27001）取得のお知らせ
            </div>
            <img src='/images/news-detail-img-2.png' alt='img' />
            <div className={styles.text}>
                <p>
                    株式会社セルミュラーは、2023年8月26日に国際規格「ISO/IEC 27001:2013」情報セキュリティマネジメントシステム（ISMS）を取得したことをご報告いたします。認証取得に伴いより一層お客様の情報資産の適切運用及び、包括的な情報セキュリティ管理の強化を図って参ります。また、大きく変化する経営戦略に強固に対応することを徹底いたします。
                </p>
                <div>
                    <div>【認証登録概要】</div>
                    <ul>
                        <li>- 株式会社セルミュラー</li>
                        <li>・広告代理事業 ・マーケティングコンサルティング ・Web 制作 ・データ分析</li>
                    </ul>

                    <ul>
                        <li>- 認証登録日</li>
                        <li>・2023年8月27日</li>
                    </ul>

                    <ul>
                        <li>- 適用規格</li>
                        <li>・JIS Q 27001:2013</li>
                    </ul>

                    <ul>
                        <li>- 認証登録番号</li>
                        <li>・C0780836-IM 1</li>
                    </ul>

                </div>
            </div>
        </section>
    </div>
  )
}

export default NewsDetail2
