import React, { useState } from 'react';
import styles from './Contact.module.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';

const Contact = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const initialData = location.state?.data || {
    name: '',
    email: '',
    emailConf: '',
    content: '',
  };

  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
  
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    }
  
    if (!formData.emailConf.trim()) {
      newErrors.emailConf = "Email confirmation is required";
    } else if (formData.email !== formData.emailConf) {
      newErrors.emailConf = "Emails do not match";
    }
  
    if (!formData.content.trim()) {
      newErrors.content = "Content is required";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      navigate('/confirm', { state: { data: formData } });
    }
  };
  
  

  return (
    <div className={styles.container}>
      <section className={styles.fv_area}>
        <h1>CONTACT</h1>
      </section>
      <section className={styles.form_area}>
        <div className={styles.flex}>
          <div>
            <h2>Contact Us</h2>
          </div>
          <div>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField 
                name="name"
                label="name" 
                variant="standard" 
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
              />
              <TextField 
                name="email"
                label="email" 
                variant="standard" 
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField 
                name="emailConf"
                label="email-conf" 
                variant="standard" 
                onChange={handleChange}
                error={!!errors.emailConf}
                helperText={errors.emailConf}
              />
              <TextField
                name="content"
                label="content"
                multiline
                maxRows={4}
                variant="standard"
                onChange={handleChange}
                error={!!errors.content}
                helperText={errors.content}
              />
              <div className={styles.button_wrapper}>
                <Button 
                  variant="outlined"
                  type="submit"
                  disabled={Object.keys(errors).length > 0}
                >
                  Confirm
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
